<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      color="secondary"
      app
      :right="$i18n.locale == 'ar' || $i18n.locale == 'kr'"
      width="290"
    >
      <!-- :color="$vuetify.theme.dark ? '#1E1E1E' : '#1E1E1E'" -->
      <!-- <pre dir="ltr">
        {{ $config }}
      </pre> -->
      <div class="text-center my-2">
        <v-avatar size="100">
          <v-img :src="$config.logo" />
          <!-- <v-img :src="$config.logo" /> -->
        </v-avatar>
        <h3 class="mt-2 white--text">{{$config.title}}</h3>
        <h3 class="mt-2 white--text">منظومة سير العمل الإداري</h3>
        <h3 class="mt-2" style="color: #0080ff">|| مسعى ||</h3>
      </div>
      <v-divider />

      <v-row class="ma-2" align="center">
        <!-- <v-container> -->
        <v-col cols="3">
          <v-avatar size="60" rounded>
            <v-img
              :src="
                $store.state.user.user.imageUrl
                  ? 'https://api-mas3a.iips.edu.iq' + '/' + $store.state.user.user.imageUrl
                  : this.$store.state.defaultPhoto
              "
            />
          </v-avatar>
        </v-col>
        <v-col>
          <h4 class="white--text mb-1">{{ $store.state.user.fullName }}</h4>
          <v-chip label dense outlined color="#0080FF">
            <!-- {{ $store.state.user.user.organization == null ? "Admin" :  $store.state.user.user.organization.name   }} -->
            {{
              $store.state.user.user.jobTitle == null
                ? "Admin"
                : $store.state.user.user.jobTitle
            }}
          </v-chip>
        </v-col>
      </v-row>
      <v-divider />

      <tree />
      <!-- <template v-slot:append>
            <div class="pa-6">
                <span style="color: #7c7c7c">
                    تم التطوير بواسطة
                    <a href="https://www.google.com/">كلية معلوماتيةالأعمال</a> |
                    {{ new Date().getFullYear() }}
                </span>
            </div>
        </template> -->

      <v-divider />
      <template>
        <div class="pa-2">
          <span style="color: #7c7c7c">
            {{ $t("tm-alttwyr-bwasth") }}
            <a href="http://altanmiya-iq.com">{{$config.company}}</a>
            | {{ new Date().getFullYear() }}
          </span>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  components: {
    tree: () => import("./tree.vue"),
    // profile: () => import('./Profile'),
  },
  data() {
    return {
      loading: false,
    };
  },
  props: ["drawer"],
  methods: {
    logout() {
      this.loading = true;
      this.$http.delete("logout").finally(() => {
        this.loading = false;
        sessionStorage.removeItem("token");
        this.$router.push("/login");
      });
    },
  },

  mounted() {
    console.log(this.$store.state.user);
  },
};
</script>
